/* --------------------------------
   custom owl carousel styles
-------------------------------- */

.owl-theme
{
	.owl-dots
	{
		line-height: 1;
		margin-top: 20px !important;

		.owl-dot
		{
			@extend %v-top;

			span
			{
				width: 13px;
				height: 13px;
				background-color: #37b34a;
				box-shadow: 0 0 0 0 #37b34a inset;
				margin: 5px 4px;
				@include border-radius(50%);
				@include transition(
					background 0.3s ease-in-out,
					box-shadow 0.3s ease-in-out,
					opacity 0.3s ease-in-out
				);

				[style="background-color: #AE9C32;"] & { background-color: #ae9c32; }
			}

			&.active,
			&:hover
			{
				span
				{
					box-shadow: 0 0 0 8px #37b34a inset;
				}
			}
		}
	}
}