/* main colors */
$white               : #fff !default;
$primary-color       : #4a8b71 !default;
$secondary-color     : #AE9C32 !default;
$primary-color-light : lighten(desaturate(adjust-hue(#4a8b71, 1.5000), 8.2942), 51.1765);

/* main fonts */
$primary-fontFamily   : -apple-system, 'Raleway', sans-serif !default;
$secondary-fontFamily : 'Poppins', sans-serif !default;

$base-font-size: 10px !default;

/* main breakpoint */
$xl-width: 1200px !default;
$lg-width: 992px !default;
$md-width: 768px !default;
$sm-width: 576px !default;